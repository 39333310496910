.lineTooltip {
  padding: 20px;
  background-color: rgba(10, 0, 50, 0.7);
  color: white;
  font-weight: bold;
  border-radius: 10px;
  min-width: 250px;
}
.lineTooltipTitle {
  text-align: left;
  font-size: 12pt;
}
.lineTooltipValue {
  text-align: left;
  font-size: 12pt;
}
/*# sourceMappingURL=charts.module.css.map */