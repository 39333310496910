body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.container {
  margin: 0;
}
.title {
  font-size: 14pt;
  color: var(--dark-text-color);
  display: block;
  padding: 10px;
}
.cashflow {
  color: var(--dark-text-color);
  font-size: 12pt;
  padding: 20px;
}
.cashflow text {
  font-style: normal;
  font-family: 'mono' !important;
  font-size: 11pt !important;
  font-weight: lighter;
  color: var(--dark-text-color);
  stroke: var(--dark-text-color);
  fill: var(--dark-text-color);
}
.pieChart text {
  stroke: var(--dark-text-color);
  fill: var(--dark-text-color);
  stroke-width: 0.5;
  stroke-width: 0.1;
}
.bulletContainer {
  border: 0;
  padding: 0;
}
svg {
  overflow: visible;
}
/* Desktops */
@media (min-width: 1100px) {
  .cashflow {
    font-size: 1vh;
  }
  .cashflow text {
    font-size: 13pt !important;
  }
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  .card > div {
    margin: 20px;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 3vw !important;
    padding-bottom: 10px;
  }
  .pie {
    padding: 0;
    margin: 0;
  }
  .pieChart {
    height: calc(20vh + 20vw) !important;
  }
  .bullet {
    height: 20vh;
    max-height: 150px;
    max-width: 40vw;
    border: 0;
    padding: 0;
    margin-bottom: 30px;
  }
}
/* Tablets & portrait phones */
@media (min-width: 481px) and (max-width: 1100px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 16pt !important;
    padding-bottom: 10px;
  }
  .pie {
    padding: 0;
    margin: 0;
  }
  .pieChart {
    height: calc(20vh + 20vw) !important;
  }
  .bullet {
    height: 1px;
    min-height: 100px;
    max-height: 150px;
    border: 0;
    padding: 0;
  }
}
/* Phones */
@media (max-width: 481px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  :global(.card-content) {
    padding: 10px !important;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 12pt !important;
  }
  .pie {
    padding: 0;
  }
  .pie:last-of-type {
    margin-top: 140px;
    margin-bottom: 80px;
  }
  .pieChart {
    height: calc(20vh + 20vw) !important;
  }
  .calendar {
    margin-bottom: 10px;
    height: 20vh;
    min-height: 150px;
  }
  .bullet {
    margin-bottom: 30px;
    height: 20vh;
    max-height: 150px;
    border: 0;
    padding: 0;
  }
}
/*# sourceMappingURL=cashflow.module.css.map */