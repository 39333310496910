body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.sidebar {
  height: calc(90vh - 5px);
  position: fixed;
  top: 30px;
  left: 20px;
  text-align: left;
  max-width: 250px;
  border-right: 0.1px solid var(--dark-border-color);
  overflow-y: auto;
}
.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.sidebarLink {
  padding: 0px !important;
  margin: 4px !important;
}
@media (min-width: 1100px) {
  .menu {
    text-align: left;
  }
  .menu a {
    color: var(--dark-text-color);
    font-size: 13pt;
    width: 100%;
    height: 100%;
  }
  .logo {
    height: 100px;
    text-align: center;
    color: var(--dark-text-color);
    font-size: 12pt;
  }
}
@media (min-width: 481px) and (max-width: 1100px) {
  .sidebar {
    width: 80px !important;
  }
  .menu {
    text-align: left;
    padding: 0 !important;
  }
  .menu a {
    color: var(--dark-text-color);
    font-size: 21pt;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .logo {
    height: 100px;
    text-align: center;
    color: var(--dark-text-color);
    font-size: 0pt;
    margin-bottom: 50px;
  }
}
@media (max-width: 481px) {
  .sidebar {
    width: 65px !important;
  }
  .menu {
    text-align: left;
    padding: 0 !important;
  }
  .menu a {
    color: var(--dark-text-color);
    font-size: 17pt;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .logo {
    height: 35px;
    text-align: center;
    color: var(--dark-text-color);
    font-size: 0pt;
    margin-bottom: 10px;
  }
}
.menu p {
  color: #99a;
  font-size: 11pt;
}
.menu a:hover {
  background-color: #485fc7;
  color: var(--dark-text-color);
}
.createContainer {
  color: var(--dark-text-color);
  align-items: "center";
  justify-content: "center";
}
.create {
  right: 0px;
}
:global(.menu-label) {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
:global(.menu-list a) {
  padding-top: 3px;
  padding-bottom: 3px;
}
:global(.dropdown-divider) {
  background-color: #6c6c80;
}
.create button {
  background: none !important;
  border: none !important;
  box-shadow: none;
}
.create span {
  color: var(--dark-text-color);
}
@media (min-width: 481px) {
  .newItemModalContent {
    padding: 50px;
    border-radius: 20px;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
    min-width: 50vw;
  }
  .newItemModalClose {
    font-size: 20pt;
    position: absolute;
    right: 50px;
    top: 50px;
  }
  .newItemClass {
    font-size: 16pt;
    padding: 10px;
    text-align: left;
  }
  .newItemOption {
    color: var(--dark-text-color);
    font-size: 16pt;
    padding-left: 20px;
    width: 100%;
  }
}
@media (max-width: 481px) {
  .newItemModalContent {
    padding: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    bottom: 0;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
    width: 100vw;
    height: 100%;
  }
  .newItemModalClose {
    font-size: 20pt;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .newItemClass {
    font-size: 12pt;
    padding: 20px;
    text-align: left;
  }
  .newItemOption {
    color: var(--dark-text-color);
    font-size: 12pt;
    padding-left: 20px;
    width: 100%;
  }
}
.newItemModalTitle {
  display: inline-flex;
}
.newItemModalClose:hover {
  cursor: pointer;
}
.newItemModalContent h1 {
  padding-bottom: 10px;
  font-size: 30pt;
  border-bottom: 2px solid red;
  margin-bottom: 20px;
}
.newItemCell {
  padding: 0;
  width: 100%;
}
.newItemCell:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.newItemOption:hover {
  color: var(--dark-text-color);
}
.newItemCell a {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=sidebar.module.css.map */