.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  margin: 20px;
  background: var(--dark-input-color);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 40px;
  background: red;
  cursor: pointer;
  border: none;
}
.slider::-moz-range-thumb {
  width: 10px;
  height: 40px;
  background: red;
  cursor: pointer;
  border: none;
}
.thumb {
  background-color: red;
}
.track {
  background-color: blue;
  height: 10px;
}
.datePicker input {
  color: var(--dark-text-color) !important;
}
.inputLabel {
  font-size: 13pt;
}
.title {
  font-size: 14pt;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid red;
  margin-bottom: 15px;
}
.inputLabel {
  text-align: left;
  padding-left: 50px;
}
.stageButton {
  background: none;
  color: var(--dark-text-color);
}
/*# sourceMappingURL=settings.module.css.map */