body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.title {
  font-size: 12pt;
  color: var(--dark-text-color);
}
.ledger {
  /* background-color: green;
  height: 200px; */
  padding: 20px;
}
.search {
  margin: 20px;
}
.search input {
  background: none;
  color: var(--dark-text-color);
  border: none;
  border: 2px solid var(--dark-input-color);
  /* border-bottom: 2px dashed var(--dark-input-color); */
}
.search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--dark-text-color);
  opacity: 0.5;
  /* Firefox */
}
.search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--dark-text-color);
}
.search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--dark-text-color);
}
/*# sourceMappingURL=ledger.module.css.map */