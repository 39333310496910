body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.tableContainer {
  color: var(--dark-text-color);
  font-size: 9pt;
  overflow-y: auto;
}
.thead {
  font-size: 11pt;
  color: var(--dark-text-color);
}
.th {
  color: var(--dark-text-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
}
.trhead {
  background-color: var(--dark-table-head);
  border-top: 0.1px solid var(--dark-input-color);
}
.tbody {
  font-size: 11pt;
}
.tr {
  border-top: 0.1px solid var(--dark-input-color);
}
.tbody :hover {
  background-color: var(--dark-table-head);
}
.td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}
.tr :hover {
  background-color: var(--dark-table-hover);
  cursor: pointer;
}
.rowModalTitle {
  display: inline-flex;
}
@media (min-width: 481px) {
  .rowModalContent {
    padding: 50px;
    border-radius: 20px;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
  }
  .rowModalClose {
    font-size: 20pt;
    position: absolute;
    right: 50px;
    top: 50px;
  }
  .rowModalClose:hover {
    cursor: pointer;
  }
}
@media (max-width: 481px) {
  .rowModalContent {
    padding: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    bottom: 0;
    background-color: var(--dark-modal-background);
    color: var(--dark-text-color);
    width: 100vw;
    height: 100%;
  }
  .rowModalClose {
    font-size: 20pt;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.rowModalContent h1 {
  padding-bottom: 10px;
  padding-top: 20px;
  font-size: 30pt;
  border-bottom: 2px solid red;
  margin-bottom: 20px;
}
.rowModalHeader {
  padding: 5px;
  margin-right: 30px;
}
.rowModalRow {
  border-bottom: 0.1px solid var(--dark-input-color);
}
.rowModalActions {
  margin-top: 20px;
}
.rowModalAction {
  width: 100%;
  background-color: inherit;
  color: var(--dark-text-color);
  border-color: var(--dark-border-color);
}
.rowModalAction:hover {
  background-color: var(--dark-table-hover);
  color: var(--dark-text-color);
  border-color: var(--dark-border-color);
}
/*# sourceMappingURL=table.module.css.map */