body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.everything {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  text-align: center;
  /* background-image: url('../assets/background2.jpg'); */
  background: linear-gradient(135deg, var(--dark-gradient-1) 0%, var(--dark-gradient-2) 100%);
  /* background: linear-gradient(90deg, rgba(131,58,180,1) 2%, rgba(253,29,9,1) 71%, rgba(252,176,69,1) 100%); */
  /* background: radial-gradient(circle, rgba(195,34,145,1) 0%, rgba(253,114,45,1) 100%); */
  background-size: 100% 100%;
  padding: 10px;
  font-size: 10pt;
  color: var(--dark-text-color);
}
@media (max-width: 481px) {
  .everything {
    padding: 5px;
  }
}
@media (min-width: 1100px) {
  .something {
    background-color: var(--dark-background-color);
    border-radius: 30px;
    height: calc(100vh - 20px);
    overflow: auto;
  }
  .content {
    margin-right: 50px;
  }
  .customer {
    font-size: 30pt;
    font-family: cursive;
    color: var(--dark-text-color);
  }
}
@media (min-width: 481px) and (max-width: 1100px) {
  .something {
    background-color: var(--dark-background-color);
    border-radius: 30px;
    height: calc(100vh - 20px);
    overflow: auto;
  }
  .content {
    margin-right: 20px;
  }
  .customer {
    font-size: 25pt;
    font-family: cursive;
    color: var(--dark-text-color);
  }
}
@media (max-width: 481px) {
  .something {
    background-color: var(--dark-background-color);
    border-radius: 20px;
    height: calc(100vh - 10px);
    overflow: auto;
  }
  .customer {
    font-size: 20pt;
    font-family: cursive;
    color: var(--dark-text-color);
  }
}
.header {
  height: 50px;
  border-bottom: 1px solid var(--dark-border-color);
  margin: 10px;
}
.timeButton {
  width: 100%;
  height: 50px !important;
  margin: none !important;
  background-color: inherit !important;
  color: var(--dark-text-color) !important;
  border: none !important;
  border-radius: 0 !important;
  border-right: 1px solid var(--dark-border-color) !important;
}
.timeButtonActive {
  width: 100%;
  height: 50px !important;
  margin: none !important;
  background-color: inherit !important;
  color: var(--dark-text-color) !important;
  border: none !important;
  border-radius: 0 !important;
  border-right: 1px solid var(--dark-border-color) !important;
  border-bottom: 5px solid red !important;
}
.timeButton:hover {
  background-color: rgba(50, 0, 200, 0.4) !important;
  color: var(--dark-text-color);
  border-color: var(--dark-border-color);
}
.searchModalContent {
  padding: 50px;
  border-radius: 20px;
  background-color: var(--dark-modal-background);
  color: var(--dark-text-color);
}
.searchModalContent h1 {
  padding-bottom: 10px;
  font-size: 30pt;
  border-bottom: 2px solid red;
  margin-bottom: 20px;
}
.search {
  margin: 20px;
}
.search input {
  background: none;
  color: var(--dark-text-color);
  border: none;
  border: 2px solid var(--dark-input-color);
  padding: 30px;
  font-size: 15pt;
}
.search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--dark-text-color);
  opacity: 0.5;
  /* Firefox */
}
.search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--dark-text-color);
}
.search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--dark-text-color);
}
/*# sourceMappingURL=everything.module.css.map */