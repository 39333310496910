body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.container {
  overflow: none;
}
.container text {
  stroke: #fff;
  stroke-width: 1.2;
  font-style: normal;
  font-family: "Source Code Pro" !important;
  font-size: 9pt !important;
  font-weight: lighter;
  padding: 20px;
}
.tableHeader {
  font-size: 14pt;
  padding: 5px;
}
/* Desktops */
@media (min-width: 1100px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  .card > div {
    margin: 20px;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 3vw !important;
    padding-bottom: 10px;
    /* border-bottom: 10px solid #24145f; */
  }
  .treemap {
    height: 50vh;
  }
  .table {
    overflow-y: scroll;
    height: 30vh;
    border-bottom: 1px solid var(--dark-input-color);
  }
}
/* Tablets & portrait phones */
@media (max-width: 1100px) and (min-width: 481px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 16pt !important;
    padding-bottom: 10px;
  }
  .treemap {
    height: 55vh;
  }
  .table {
    overflow-y: none;
    height: 70vh;
    width: 70vw;
    border-bottom: 1px solid var(--dark-input-color);
  }
}
/* Phones */
@media (max-width: 481px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  :global(.card-content) {
    padding: 10px !important;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 12pt !important;
    /* padding-bottom: 10px; */
    /* border-bottom: 10px solid #24145f; */
  }
  .treemap {
    height: 150vh;
  }
  .table {
    overflow-y: scroll;
    height: 90vh;
    border-bottom: 1px solid var(--dark-input-color);
  }
}
/*# sourceMappingURL=credit.module.css.map */