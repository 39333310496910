body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: var(--dark-scrollbar-color) #02020c;
}
/* Works on Chrome, Edge, and Safari */
@media (min-width: 481px) {
  *::-webkit-scrollbar {
    width: 10px;
  }
}
@media (max-width: 481px) {
  *::-webkit-scrollbar {
    width: 0px;
  }
}
*::-webkit-scrollbar-track {
  background: #d4d4d4;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--dark-scrollbar-color);
  border-radius: 10px;
  border: 3px solid #d4d4d4;
}
.container {
  overflow: none;
}
.title {
  font-size: 13pt;
  color: var(--dark-text-color);
}
.container text {
  stroke: var(--dark-text-color);
  stroke-width: 1.2;
  font-style: normal;
  font-family: "Source Code Pro" !important;
  font-size: 11pt !important;
  font-weight: lighter;
  padding: 20px;
}
/* Desktops */
@media (min-width: 1100px) {
  .card {
    background-color: inherit;
    border-radius: 30px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
    min-height: 10vh;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 4vw !important;
  }
  .line {
    height: 60vh;
  }
  .calendar {
    height: 200px;
    min-height: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .tables {
    overflow-y: scroll;
    margin-bottom: 30px;
    height: 30vh !important;
    min-height: 300px;
    border-bottom: 1px solid red;
  }
}
/* Tablets & portrait phones */
@media (max-width: 1100px) and (min-width: 481px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 14pt !important;
  }
  .line {
    height: 90vh;
    max-height: 400px;
  }
  .calendar {
    height: 150px;
    min-height: 150px;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .tables {
    overflow-y: scroll;
    margin-bottom: 30px;
    height: 20vh !important;
    min-height: 300px;
    border-bottom: 1px solid red;
  }
}
/* Phones */
@media (max-width: 481px) {
  .card {
    background-color: inherit;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px var(--dark-card-shadow);
    margin-top: 25px;
    margin-bottom: 15px;
  }
  :global(.card-content) {
    padding: 10px !important;
  }
  .card * {
    color: var(--dark-card-color);
  }
  .metric {
    font-size: 12pt !important;
  }
  .line {
    height: 35vh;
    max-height: 500px;
  }
  .calendar {
    height: 150px;
    min-height: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .tables {
    overflow-y: scroll;
    height: 40vh !important;
    border-bottom: 1px solid red;
  }
}
/*# sourceMappingURL=flows.module.css.map */